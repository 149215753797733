<template>
    <v-card>
        <v-card-title>
            <v-dialog v-model="dialog" max-width="800px">
                <template v-slot:activator="{ on }">
                    <v-btn color="primary" dark class="mb-2" v-on="on">{{ trans('buttons.cpaNetwork.newItem') }}</v-btn>
                </template>
                <cpa-network-form v-if="dialogLoaded" :options="options" :itemId="editedItem" v-on:saved="onSave" v-on:close="close"></cpa-network-form>
            </v-dialog>
            <delete-dialog
                :dialogTrigger="deleteDialog"
                :item-id="deleteItemId"
                :delete-route="deleteRoute"
                @closed="deleteDialogClosed"
                @deleted="deleteDialogDeleted"
            >
                <template v-slot:title>
                    {{ trans('pages.cpaNetwork.deleteDialogTitle') }}
                </template>
                <template v-slot:text>
                    {{ trans('pages.cpaNetwork.deleteDialogText', {id: deleteItemText ? '"'+deleteItemText+'"' : deleteItemId}) }}
                </template>
            </delete-dialog>
            <v-spacer></v-spacer>
            <v-icon
                class="pull-right"
                color="primary"
                @click="filterShow = filterShow === 0 ? null : 0"
            >mdi-filter</v-icon>
            <v-expansion-panels
                v-model="filterShow"
                flat
                tile
            >
                <v-expansion-panel>
                    <v-expansion-panel-content class="pt-4">
                        <v-row>
                            <v-col style="min-width: 200px;">
                                <v-text-field
                                    v-model="filters.label"
                                    :label="trans('fields.common.label')"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-btn
                                class="ma-2"
                                color="primary"
                                outlined
                                @click="applyFilters"
                            >{{ trans('buttons.common.apply') }}</v-btn>
                            <v-btn
                                class="ma-2"
                                color="orange"
                                outlined
                                @click="clearFilters"
                            >{{ trans('buttons.common.clear') }}</v-btn>
                        </v-row>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-card-title>
        <v-data-table
            :headers="headers"
            :items="cpaNetworks.data"
            :items-per-page="paginationParams.per_page"
            :page="paginationParams.page"
            :server-items-length="cpaNetworks.total"
            :loading="loading"
            :footer-props="footerProps"
            sort-by="id"
            class="border"
            @update:page="changePage"
            @update:items-per-page="changeItemsPerPage"
        >
            <template v-slot:item.actions="{ item }">
                <v-icon
                    v-if="item.user_id"
                    small
                    class="mr-2"
                    @click="editItem(item)"
                >mdi-pencil</v-icon>
                <v-icon
                    v-if="item.user_id"
                    small
                    @click="deleteDialogShow(item.id, item.label)"
                >mdi-delete</v-icon>
            </template>
            <template v-slot:item.created_at="{ item }">
                {{ item.created_at | mysqlDate }}
            </template>
            <template v-slot:item.user="{ item }">
                <template v-if="item.user">{{ item.user.id }} :: {{ item.user.email }}</template>
            </template>
            <template v-slot:item.scope="{ item }">
                <template v-if="options.cpaNetworkScopesKeyed">{{ options.cpaNetworkScopesKeyed[item.scope] }}</template>
            </template>
        </v-data-table>
    </v-card>
</template>

<script>
    import tableWithPagination from "@/mixins/tableWithPagination";
    import DeleteDialog from "@/components/commonComponents/DeleteDialog";
    import deleteDialog from "@/mixins/deleteDialog";
    import tableFilters from "@/mixins/tableFilters";

    export default {
        name: "CpaNetworkTable",
        components: {
            CpaNetworkForm: () => import('@/components/user/cpaNetwork/CpaNetworkForm'),
            DeleteDialog,
        },
        mixins: [
            tableWithPagination,
            deleteDialog,
            tableFilters
        ],
        data() {
            return {
                dialog: false,
                dialogLoaded: false,
                cpaNetworks: {},
                headers: [
                    { text: "id", sortable: false, value: "id" },
                    { text: this.trans("fields.common.label"), value: "label", sortable: false },
                    { text: this.trans("fields.common.scope"), value: "scope", sortable: false },
                    { text: this.trans("fields.common.created_at_f"), value: "created_at", sortable: false },
                    { text: this.trans("fields.common.actions"), value: "actions", sortable: false },
                ],
                paginationParams: {
                    page: 1,
                    per_page: 10,
                },
                url: this.route('user.cpaNetworks.index'),
                deleteRoute: 'user.cpaNetworks.delete',

                filters: {
                    label: '',
                },
                filtersDefault: {
                    label: '',
                },

                editedItem: null,
            }
        },
        created() {
            this.editedItem = null;
            this.loadOptions({cpaNetworkScopes: true});
        },
        watch: {
            dialog(val) {
                if (val){
                    this.dialogLoaded = true
                } else {
                    this.close();
                }
            }
        },
        methods: {
            onSave(){
                this.close();
                this.initialize();
            },
            editItem(item) {
                this.editedItem = item.id;
                this.dialog = true;
                this.dialogLoaded = true;
            },
            close() {
                this.dialog = false;
                setTimeout(() => {
                    this.editedItem = null;
                    this.dialogLoaded = false;
                }, 300);
            },


        },
        computed: {
            paginationItems: {
                get(){
                    return this.cpaNetworks;
                },
                set(v){
                    this.cpaNetworks = v;
                }
            },
        }
    }
</script>

<style scoped>

</style>
